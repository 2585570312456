import { createApp } from 'vue'
import FloatingVue from 'floating-vue'
import App from './App.vue'
import router from './lib/router'
import VueMatomo from "vue-matomo"
import { systemConfig } from './lib/systemConfig'
import './index.css'
import 'floating-vue/dist/style.css'

const { matomoHost, matomoSiteId } = systemConfig()

const app = createApp(App)
    .use(router)
    .use(FloatingVue)
    .use(VueMatomo, 
        {
            host: matomoHost,
            siteId: matomoSiteId,
            router: router,
        })

app.mount('#app')
