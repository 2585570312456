
const trackEvent = (category: string, action: string, name: string) => {
    if (window._paq) {
        window._paq.push(['trackEvent', category, action, name])
    }
}

// set user id  
const setUserId = (id: string) => {
    if (window._paq) {
        window._paq.push(['setUserId', id])
    }
}

export const useAnalytics = () => {

    return { trackEvent, setUserId }
}