<template>
  <footer class="body-font">
    <hr class="h-px bg-gray-900">
    <div class="container bar-height mx-auto flex items-center mt-2 my-1 px-10 max-w-5xl place-content-between">
      <div class="flex">
        <dark-icon
          v-if="isDark"
          class="h-6 cursor-pointer hover:scale-110 text-neutral-200"
          @click="toggleDark(), trackEvent('theme', 'Dark', 'Dark')"
        />
        <light-icon
          v-else
          class="h-6 cursor-pointer hover:scale-110 text-neutral-500"
          @click="toggleDark(), trackEvent('theme', 'Light', 'Light')"
        />
      </div>

      <logo-dark
        v-if="isDark"
        class="logo-size"
      />
      <logo-light
        v-else
        class="logo-size"
      />
    </div>

    <div class="bg-gray-400 dark:bg-neutral-950">
      <div class="container mx-auto px-10 max-w-5xl">
        <div class="flex flex-col items-center justify-center py-2">
          <p class="text-gray-900 dark:text-gray-300 text-xs text-center">
            © 2024 DeltaeLab. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts" setup>
import { useDark, useToggle } from '@vueuse/core'
import darkIcon from '@/assets/icons/darkIcon.svg?component'
import lightIcon from '@/assets/icons/lightIcon.svg?component'
import logoLight from '@/assets/logos/color-positive.svg?component'
import logoDark from '@/assets/logos/color-negative.svg?component'
import { useAnalytics } from '@/lib/useAnalytics'

const { trackEvent } = useAnalytics()
const isDark = useDark()
const toggleDark = useToggle(isDark)
</script>
<style scoped>
.logo-size {
  height: 108px;
  width: auto;
}
.bar-height {
  height: 116px;
}
</style>