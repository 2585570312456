const Mode = import.meta.env.MODE

let configMeta = {
    version: import.meta.env.VITE_PRISMA_VER,
    apiUrl: "",
    mediaUrl: "",
    matomoHost: "",
    matomoSiteId: 0,
    mpKey: ""
}

export const systemConfig = () => {
    if(Mode === 'development'){
        configMeta.apiUrl = import.meta.env.VITE_DEV_API
        configMeta.mediaUrl = import.meta.env.VITE_PRISMA_DEV_MEDIA
        configMeta.matomoHost = import.meta.env.VITE_MATOMO_DEV
        configMeta.matomoSiteId = import.meta.env.VITE_MATOMO_PRIMS_DEV
        configMeta.mpKey = import.meta.env.VITE_MP_KEY_DEV
    } else if (Mode === 'staging'){
        configMeta.apiUrl = import.meta.env.VITE_STAGE_API
        configMeta.mediaUrl = import.meta.env.VITE_PRISMA_STAGE_MEDIA
        configMeta.matomoHost = import.meta.env.VITE_MATOMO_DEV
        configMeta.matomoSiteId = import.meta.env.VITE_MATOMO_PRIMS_STAGE
        configMeta.mpKey = import.meta.env.VITE_MP_KEY_DEV
    } else if (Mode === 'production'){
        configMeta.apiUrl = import.meta.env.VITE_PROD_API
        configMeta.mediaUrl = import.meta.env.VITE_PRISMA_PROD_MEDIA
        configMeta.matomoHost = import.meta.env.VITE_MATOMO_STAGEPROD
        configMeta.matomoSiteId = import.meta.env.VITE_MATOMO_PRIMS_PROD
        configMeta.mpKey = import.meta.env.VITE_MP_KEY_PROD
    }
    return configMeta
}