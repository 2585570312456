import axios, { AxiosInstance } from 'axios'
import { systemConfig } from './lib/systemConfig'

const { apiUrl, mediaUrl } = systemConfig()

type ApiConfig = {
  withCredentials: boolean
  headers: {
    'Content-Type': string
  }
  baseURL: string
  media: string
}

const baseConfig = () => {
    return {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: apiUrl,
      media: mediaUrl,
    } as ApiConfig
  }

const http = axios.create(baseConfig()) as AxiosInstance

export default http